const AboutStore = {
  data: {
    title: 'Meet Darceo',
    copy: "Darceo is a global provider of engineering services for the development of products in the Industrial, Mil/Aero, and Medical markets. Darceo serves fortune 50 tier 1 customers with highly complex system development projects to start up entrepreneurs with great product ideas. Darceo expertise is centered around electronics, firmware, and mechanical engineering and is used to support basic pcba projects up through complex systems such as locomotives, power grid controls systems, home and commercial automation systems. Advanced Medical systems are a growing segment of the business in ophthalmology surgery, audiology, and heart diagnostic systems.",
    sections: [
      {
        title: 'Customer Service',
        copy: 'Focused on building intimate relationships with its customers, the company provides integrated and comprehensive end-to-end solutions based on open standards, with a unique combination of commercial development tools, and professional development experience and technical advisory services. Darceo makes the future safe by building designs and processes that help our customers reduce development risk, improve time-to-market, and lower costs in complex projects, in addition to developing certifiable applications.'
      },
      {
        title: 'Our Customers',
        copy: 'At Darceo, we build customer value through continual innovation and the rapid advancement of technology. We are committed to providing easy-to-use integrated disciplines that meet the rigorous demands of our customers’ complex projects. With recognized expertise in the industrial, medical and mil/aerospace markets, the company services the world’s most respected organizations such as GE, Honeywell, Eaton, USAFRL, BAE, and USARL.'
      },
      {
        title: 'Our History',
        copy: 'Darceo was created when Space Craft International (SCI) closed a large facility in Rapid City South Dakota. With not enough technical positions to absorb the hundreds of engineers released the Darceo team was formed. The team has been added to over the years and now can support large scaled projects for our customers.'
      },
      {
        title: 'Our Commitment',
        copy: 'Darceo is committed to customer satisfaction and technology innovation. This commitment is demonstrated in how we develop products, in our unwavering focus on establishing a unified, global support network for our highly technical users, and in our stewarding of open industry standards that make product development easier and more efficient for our customers.'
      }
    ],
    imgSrc: require('@/assets/darceo.png')
  }
};

export default AboutStore;