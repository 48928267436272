<template>
  <div id="about-page">
    <!-- TITLE IMAGE -->
    <v-img 
      :src="about.imgSrc" 
      gradient="to top right, rgba(0,0,0,.4), rgba(0,0,0,0)"
      max-height="400"
      cover
      position="top 35% center"
    >
    <!-- PAGE TITLE -->
      <v-container fill-height fluid>
        <v-layout fill-height justify-start align-end>
          <v-flex xs12 flexbox text-xs-left>
            <h1 
              id="title"
              style="color:white;"
              :class="{ 'display-3': $vuetify.breakpoint.xs}"
            >{{ about.title }}</h1>
          </v-flex>
        </v-layout>
      </v-container>
    </v-img>
    <!-- OVERVIEW TEXT -->
    <v-card flat>
      <v-flex xs12 sm10 offset-sm1>
        <v-card-text>
          <p id="overview-txt" :class="{ 'body-2': $vuetify.breakpoint.xs }">{{ about.copy }}</p>
        </v-card-text>
      </v-flex>
    </v-card>
    <!-- CONTENT SECTION (loop) -->
    
      <v-layout row wrap
        v-for="s in about.sections" 
        :key="s.title"
      >
        <v-flex xs12>
          <v-card flat>
            <v-flex xs12 sm8 offset-sm2>
              <v-card-title primary-title><h1>{{ s.title }}</h1></v-card-title>
              <v-card-text><p>{{ s.copy }}</p></v-card-text>
            </v-flex>
          </v-card>          
        </v-flex>
      </v-layout>

      <v-card height="250" flat></v-card>
      
    
  </div>
</template>

<script>
import AboutStore from './AboutStore.js'

export default {
  data: () => ({ about: AboutStore.data }),
}
</script>

<style scoped>
#title {
  font-size: 100pt;
  margin-left: 1%;
}
#overview-txt {
  font-size: 20pt;
  margin-top: 2%;
}
h1 {
  font-size: 46pt;
}
p {
  font-size: 16pt;
}
</style>