<template>
  <div id="about">
    <AboutPage/>
  </div>
</template>

<script>
import AboutPage from '../components/about/AboutPage.vue'

export default {
  data: () => ({  }),
  components: {AboutPage}
}
</script>
